import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default function Seo({
  seo: {
    metaDesc: pageDescription,
    title: pageTitle,
    featuredImage,
    opengraphImage,
  } = {},
}) {
  // Get defaults
  const {
    wp: {
      generalSettings: { title: siteName, description: defaultDescription },
      seo: {
        openGraph: {
          defaultImage: { sourceUrl: defaultImage },
        },
        social: {
          twitter: { cardType: twitterCardType },
        },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          seo {
            openGraph {
              defaultImage {
                sourceUrl
              }
            }
            social {
              twitter {
                cardType
              }
            }
          }
          generalSettings {
            title
            description
          }
        }
      }
    `
  )

  const description = pageDescription || defaultDescription
  const title = pageTitle || siteName
  const image =
    opengraphImage?.localFile?.publicURL || featuredImage || defaultImage

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `robots`,
          content: "index, follow",
        },
        {
          name: `viewport`,
          content: "width=device-width,initial-scale=1.0",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:url`,
          content: typeof window !== "undefined" ? window.location.href : null,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `twitter:card`,
          content: twitterCardType,
        },
      ]}
    />
  )
}
